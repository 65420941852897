import React, { PureComponent } from 'react'
import { compose } from 'redux'

import intro from './intro.svg';
import intromobile from './intro-mobile.svg';
import introdashboard from './introdashboard.svg';

import Layout from '../Layout';

import Container from '../../components/Container'
import Box from '../../components/Box'
import Text from '../../components/Text'
import BackgroundImage from '../../components/BackgroundImage'
import Button from '../../components/Button'
import VerticalCenter from '../../components/VerticalCenter'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme';
import Link from '../../components/Link'
import Title from '../../components/Title'

import withResponsive from '../../hoc/withResponsive'
import withResultReducer from '../Result/hoc';

class IndexPage extends PureComponent {
  state = {
    inited: false
  }

  componentDidMount() {
    const { pageContext: { id }, setIndex } = this.props;
    setIndex(id)
  }

  componentDidUpdate() {
    this.setState({ inited: true })
  }

  render() {
    const { pageContext: { title, id }, isMobile } = this.props;
    const { inited } = this.state
    return (
      <Layout title={title} og={`og-${id}`}>
        <BackgroundImage key={inited.toString()} position="relative" src={isMobile ? intromobile : intro} height="100%">
          {!isMobile && (
            <Box position="absolute" bottom="0" width="100%">
              <BackgroundImage src={introdashboard} ratio={422.09 / 1923} />
            </Box>
          )}
          <VerticalCenter>
            <Container align="center">
              <Box transform="rotate(-3deg)">
                <Title>
                  {title.split('，').reduce((all, l, i) => all.concat(i > 0 ? [<br key={`b-${i}`} />, l] : l), [])}
                </Title>
                <Box />
                <Box
                  my="1em"
                  py="0.15em"
                  px="1.5em"
                  bg="yellow"
                  border="solid 1.5px"
                  fontSize={mobileOrDesktop('1.5em', '2em', '1.5em', '2em')}
                  fontWeight="bold"
                  borderRadius="1em"
                  display="inline-block"
                >
                  一趟前往2030年的旅程
                </Box>
              </Box>
              <Text mt="0.25em" fontWeight="bold" lineHeight="1.5" fontSize={mobileOrDesktop('0.9em', '1.2em', '0.8em')}>
                你即將參與一趟通往「超高齡臺灣」的旅程<br/>
                旅程途中，你將看到人口結構遽變下臺灣面臨的挑戰<br/>
                現在就出發，測測看你對十年後的臺灣了解多少吧！</Text>
              <Text mt="1em" fontSize={mobileOrDesktop('0.75em', '1em')}>*超高齡社會：65歲以上老年人口佔總人口比例超過20%</Text>
              <Box mt="0.8em">
                <Button m="0.5em" py="0.35em" px="1.25em" hoverBg="yellow" is={Link} to="/question/1">START</Button>
              </Box>
              <Box height={mobileOrDesktop('35vh', '35vh', '28vh', '28vh')} />
            </Container>
          </VerticalCenter>
          <Box position="absolute" left="1em" bottom="1em" fontSize="0.75em">
            A <Link color="black" href="https://relab.cc">Re-lab</Link> work.
          </Box>
        </BackgroundImage>
      </Layout>
    )
  }
}

export default compose(withResponsive, withResultReducer)(IndexPage)
